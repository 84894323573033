.sidebar {
  position: relative;
  background: var(--bs-white);
  border-right: 1px solid #eee;
  min-height: 100vh !important;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 100px;
  box-shadow: 0 2px 4px -3px rgba(64, 60, 67, 0.24);
  z-index: 1;

  .logo-wrapper {
    height: 100px;
    padding: 0 10px;

    .logo {
      width: 100%;
      object-fit: contain;
      height: 50px;
    }
  }

  .nav {
    padding: 0 10px;
    gap: 15px;

    .nav-item {
      width: 100%;
      border-radius: 10px;

      .nav-link {
        color: #647382;
        cursor: pointer;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        i {
          font-size: 20px;
        }

        span {
          font-size: 9px;
        }

        &.active {
          background: #dfebff;
          color: var(--bs-primary);
        }
      }

      &.active {
        background: #dfebff;
        color: var(--bs-primary);
      }

      .collapse {
        &.show {
          -moz-transition: height 2s;
          -ms-transition: height 2s;
          -o-transition: height 2s;
          -webkit-transition: height 2s;
          transition: height 2s;
        }
      }
    }
  }

  .app-version {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    padding: 15px;
    font-size: 10px;
    text-align: center;
    width: 100%;
  }
}

.layout-body {
  display: flex;

  main.layout-container {
    background: aliceblue;
    flex: 1;
    z-index: 1;
    // height: 100vh;

    .header {
      box-shadow: 0 2px 4px -3px rgba(64, 60, 67, 0.24);
      border-bottom: 1px solid #eee;
      background: var(--bs-white);
      height: 75px;

      .menu-icon {
        background: #c5e4ff;
        height: 45px;
        width: 45px;
        border-radius: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;

        img {
          height: 40px;
          width: 40px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .info-section,
      .timer-section,
      .exit-section {
        flex: 1;
      }

      .info-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .test-info {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .timer-section {
        display: flex;
        align-items: center;
        justify-content: center;

        .timer {
          font-size: 16px;
          font-weight: 500;
        }
      }

      .exit-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .quit-btn {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .content {
      margin: 15px;
      min-height: calc(100vh - 105px);
      height: calc(100vh - 105px);
      max-width: calc(100vw - 30px);
      background: white;

      .app {
        height: 100%;
        background: white;

        .page-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #cdcdcd;
          padding: 10px;

          p {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.menu-bar {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    opacity: 0;
    display: none;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;

    &.show {
      opacity: 1;
      visibility: visible;
      display: block;
      position: absolute;
      left: 0;
    }
  }

  .layout-body {
    main.layout-container {
      .header {
        .logo-wrapper {
          img {
            display: block;
            height: 100%;
            width: 75px;
            object-fit: contain;
          }
        }

        .menu-icon {
          height: 40px;
          width: 40px;

          img {
            height: 35px;
            width: 35px;
          }
        }
      }

      .content {
        height: var(--content-height);
        min-height: var(--content-height);
      }
    }
  }

  .menu-bar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100vw;
    height: 70px;
    padding: 0 15px;
    background: white;
    box-shadow: 0 10px 20px 10px rgba(64, 60, 67, 0.24);

    .menu-item {
      font-size: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      flex: 1;
      padding: 15px;
      color: var(--bs-gray-700);
      text-decoration: none;
      font-weight: 500;
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid var(--bs-primary);
        color: var(--bs-primary);
        font-weight: 800;
      }

      .icon {
        font-size: 16px;
      }
    }
  }
}