.form-status-indicator {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  font-size: 19px;
}

.redux-form-error {
  @extend .form-status-indicator;
  color: var(--red);
}

.redux-form-warning {
  @extend .form-status-indicator;
  color: var(--yellow);
}

.input-field {
  position: relative;
  text-align: left;

  i.iconStyle {
    position: absolute;
    left: -15px;
    top: 17px;
    font-size: 16px;
    color: #7b91aa;
  }

  .form-label {
    font-size: 14px;
  }
}

.select-field {
  position: relative;

  select.is-invalid {
    background-position: center right 15px !important;
  }

  .redux-form-warning {
    float: unset;
    margin: 0;
    font-size: 80%;
  }

  .redux-form-error {
    @extend .redux-form-warning;
    color: var(--red);
  }
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-position: center right 10px !important;
}

//Radio Buttons
.custom-control-label::after,
.custom-control-label::before {
  top: 0.1rem !important;
}

//Password Input
.password-field {
  text-align: left;
  position: relative;

  i.iconStyle {
    position: absolute;
    left: -15px;
    top: 17px;
    font-size: 16px;
    color: #7b91aa;
  }

  i.password-eye {
    font-size: 16px;
    color: #7b91aa;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 10px;
  }

  .form-control.is-invalid {
    background-position: center right 35px !important;
  }

  small {
    letter-spacing: 0.5px;
  }
}

/* The switch - the box around the slider */
.switch-container {
  display: flex;
  align-items: center;

  .label-default {
    font-weight: 500;
  }

  .label-text {
    flex: 1;
  }

  label {
    margin: 0;
    margin-left: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    min-width: 45px;
    height: 25px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--bs-primary);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 100px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  display: flex;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  padding-left: 10px;
  font-size: 16px;
}

.custom-picker {
  .DateInput {
    width: 100%;

    .DateInput_input {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      border-radius: 4px;
      font-size: 1rem;
    }
  }
}
