@import url('./FontOpenSans.scss');
@import url('./FontAwesomeIcons.scss');

$theme-colors: (
  'primary': #2b55a3,
  'secondary': #4c79ca,
  'tertiary': #5260ff,
  'success': #27aa1f,
  'danger': #ff1a40,
  'warning': #ffc409,
  'light': #f4f5f8,
  'info': #81e9e6,
  'dark': #222428,
  'medium': #92949c,
  'highlight-blue': #e2eefc,
);

@import '../../node_modules/bootstrap/scss/bootstrap';

body {
  font-family: 'Open Sans', sans-serif !important;
}

.PageNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
}

.modal.show {
  display: block;
  background-color: #2d2f328a;
}

.link {
  text-decoration: underline;
  color: var(--bs-secondary);
  cursor: pointer;
}

.master-invalidator {
  padding: 10px;
}

.Toastify__toast-container--top-center.vertical {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.alert-box {
  padding: 5px 10px;
  font-size: 14px;
}

.font-10 {
  font-size: 10px !important;
  letter-spacing: 0.39px;
}

.font-12 {
  font-size: 12px !important;
  letter-spacing: 0.39px;
}

.font-13 {
  font-size: 13px !important;
  letter-spacing: 0.39px;
}

.cursor-pointer {
  cursor: pointer;
}

.logout-box {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .alert-msg {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    p {
      margin: 0;
      font-size: 18px;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .description {
      font-size: 15px;
    }
  }

  .action-btns {
    display: flex;
    gap: 5px;

    .btn {
      flex: 1;
      font-size: 14px;
      min-width: 80px;
    }

    .btn-success {
      color: #fff;
    }
  }
}

.btn-danger {
  background-color: var(--bs-danger);
  color: var(--bs-white);
}