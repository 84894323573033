@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans.woff2') format('woff2'),
    url('/fonts/OpenSans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.woff2') format('woff2'),
    url('../fonts/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
    url('../fonts/OpenSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/* Use the font in your CSS */
body {
  font-family: 'Open Sans', sans-serif;
}